import Vue from "vue";
import VueRouter from "vue-router";

import home from "../views/home.vue";
import termsOfService from "../views/termsOfService";
import searchDetail from "../views/searchDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    
  },

  {
    path: "/home",
    component: home,
  },
  {
    path: "/termsOfService",
    name: "termsOfService",
    component: termsOfService,
  },
  {
    path: "/searchDetail",
    component: searchDetail,
  },


];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    //滚动条在最上方
    return {y: 0}
  }
});

export default router;
